
  import productsFilterMixinCategories from '../../plugins/productsFilterMixinCategories';
  export default {
    components: {
      CategoriesFilterItem: () => import('./CategoriesFilterItem'),
    },
    mixins: [productsFilterMixinCategories],
    props: {
      categoriesLimit: {
        type: Number,
        default: 25,
      },
      productsList: Array,
      categories: Array,
      categoriesCount: Object,
      query: {
        type: Object,
        default: () => {},
      },
      allCategories: Array,
      loadedAll: Boolean,
      expanded: Boolean,
      categoriesLoading: Boolean,
      esSearch: Boolean,
    },
    data() {
      return {
        expands: {},
      };
    },
    computed: {
      queryWithoutPage() {
        return { ...(this.query || {}), page: null };
      },
    },
    methods: {
      toggleExpand(cat) {
        this.expands[cat._id] = !this.expands[cat._id];
        //console.log(this.expands);
      },
    },
  };
