
  import { mapGetters } from 'vuex';
  export default {
    name: 'FacetsFilter',
    data() {
      return {
        showAll: true,
        search: null,
      };
    },
    props: {
      groupIndex: {
        type: Number,
      },
      facetGroup: {
        type: Object,
      },
      selectedFacets: {
        type: Object,
      },
      limit: {
        type: String | Number,
        default: 10,
      },
    },
    created() {
      try {
        if (this.facetGroup.isSize) {
          const sizes = this.storeInformation.apparelSizes;
          for (let i = 0; i < this.facetGroup.facets.length; i++) {
            this.facetGroup.facets[i].order = 0;
            for (let j = 0; j < sizes.length; j++) {
              if (this.facetGroup.facets[i].name.toLowerCase() === sizes[j].toLowerCase()) {
                this.facetGroup.facets[i].order = j + 1;
                break;
              }
            }
          }
        }
        if (
          this.selectedFacets &&
          this.selectedFacets[this.facetGroup.slug] &&
          this.facetGroup.facets.find((e) => this.selectedFacets[this.facetGroup.slug][e._id])
        ) {
        }
      } catch (e) {
        console.log(e);
      }
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
      }),
      currentFacetGroup() {
        return this.$route.fullPath.split('/').pop();
      },
      moreThanLimit() {
        return this.filteredFacets.length > this.limit;
      },
      filteredFacets() {
        let facets = [];
        if (this.facetGroup.isSize) {
          facets = [...this.facetGroup.facets].sort((a, b) => (a.order > b.order ? 1 : -1));
        } else {
          facets = _.orderBy(this.facetGroup.facets, [(i) => i.name.toLowerCase()]);
        }

        if (this.search) {
          facets = facets.filter((f) => f.name.toLowerCase().includes(this.search.toLowerCase()));
        }
        if (this.facetGroup.name?.toLowerCase() === 'size' && this.storeInformation.apparelSizes) {
          const sizes = this.storeInformation.apparelSizes;
          return facets
            .map((facet) => ({ ...facet, index: sizes.findIndex((e) => e === facet.name) }))
            .sort((a, b) => a.index - b.index);
        }
        return facets;
      },
      facetsList() {
        if (!this.showAll) {
          return this.filteredFacets.slice(0, this.limit);
        }
        return this.filteredFacets;
      },
    },
    methods: {
      facetsNextRouteLink(facetGroup, facet) {
        return `${this.$route.fullPath}${
          this.$route.fullPath.split('/').includes(facetGroup.slug) ? '' : `/${facetGroup.slug}`
        }/${facet.slug}`
          .match(/[a-zA-Z0-9\/\ /\%20 \-]/gi)
          .join('');
      },
    },
  };
