
  export default {
    props: {
      label: String,
      currentCategory: Object,
      supplierCode: String,
      facets: Array,
      selectedFacets: Object,
      selectedSuppliers: Object,
    },
    data() {
      let searchVal = this.$route.query.query || this.$route.query.search || '';
      if (!searchVal) {
        searchVal = this.$route.path.split('/search/')[1];
        if (searchVal) {
          searchVal = searchVal.split('?')[0];
        } else {
          searchVal = '';
        }
      }
      return {
        searchVal,
      };
    },
    methods: {
      onSearchInput() {},
      startSearch() {
        return !this.searchVal && !this.$route.query.query ? 0 : this.$emit('reloadAll', this.searchVal);
      },
    },
  };
