
  import { mapGetters } from 'vuex';
  export default {
    name: 'ProductsFilters',
    components: {
      InventoryFilter: () => import('./InventoryFilter'),
      FacetsFilter: () => import('./FacetsFilter'),
      PriceFilter: () => import('./PriceFilter'),
      SearchFilter: () => import('./SearchFilter'),
      CategoriesFilter: () => import('./CategoriesFilter'),
      SupplierFilter: () => import('./SupplierFilter'),
      CLoseOutFilter: () => import('../categories/closeout'),
      NoMinimumFilter: () => import('../categories/no-minimum'),
    },
    props: {
      priceRange: Array,
      rangeData: Array,
      facets: {
        type: Array,
        default: () => [],
      },
      totalInventory: {
        type: Array,
        default: () => [],
      },
      totalCloseOut: Number,
      totalNoMinimum: Number,
      categoriesLimit: Number,
      productsList: Array,
      categories: Array,
      allCategories: Array,
      loadedAll: Boolean,
      categoriesLoading: Boolean,
      esSearch: Boolean,
      expanded: Boolean,
      searchLabel: String,
      categoriesCount: Object,
      selectedFacets: Object,
      groupsWithFacets: Array,
      mainCategory: Object,
      categoriesConfig: {
        type: Object,
        default: () => ({}),
      },
      suppliers: {
        type: Array,
        default: () => [],
      },

      selectedSuppliers: {
        type: Object,
        default: () => ({}),
      },
      currentCategory: Object,
      facetsFilter: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
      }),
      filteredFacetGroups() {
        // const currentFacetGroup = this.$route.params.slug
        //   ? this.$route.params.slug.replace(/\W/g, '').toLowerCase()
        //   : null;
        return this.groupsWithFacets.filter(
          (g) => g && g.facets.some((f) => f.activeProductsCount > 0) //&&
          // (!currentFacetGroup || currentFacetGroup === g.name.replace(/\W/g, '').toLowerCase()) //&& ['Eco Friendly', 'Brand'].includes(g.name)
        );
        // return this.groupsWithFacets.filter(g => g && g.facets.some(f => f.activeProductsCount > 0));
      },
    },
  };
