
  export default {
    name: 'InventoryFilter',
    data() {
      const { inventory } = this.$route.query || {};
      const selectedInventory = {};
      if (inventory) {
        if (Array.isArray(inventory)) {
          inventory.forEach((key) => (selectedInventory[key] = true));
        } else {
          selectedInventory[inventory] = true;
        }
      }
      return {
        selectedInventory,
      };
    },
    props: {
      totalInventory: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      onInventoryFilter(ev) {
        let next = this.$route.path;
        const query = JSON.parse(JSON.stringify(this.$route.query));
        Object.assign(query, {
          inventory: Object.keys(this.selectedInventory).filter((key) => this.selectedInventory[key]),
        });
        if (!query.inventory.length) delete query.inventory;
        this.$router.push({ path: next, query });
      },
    },
    computed: {
      inventoryWithProductsCount() {
        return this.totalInventory
          .filter((inventory) => inventory.productsCount > 0)
          .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1));
      },
    },
  };
