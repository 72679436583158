
  import productsFilterMixinCategories from '../../plugins/productsFilterMixinCategories';
  export default {
    components: {
      CategoriesFilterNestedItem: () => import('./CategoriesFilterNestedItem'),
    },
    mixins: [productsFilterMixinCategories],
    props: {
      expanded: Boolean,
      showCount: Boolean,
      total: Number,
      cat: Object,
      query: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        isExpand: this.expanded,
      };
    },
  };
