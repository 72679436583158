import { render, staticRenderFns } from "./ProductsFilters.vue?vue&type=template&id=2c4a012a"
import script from "./ProductsFilters.vue?vue&type=script&lang=js"
export * from "./ProductsFilters.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoriesFilter: require('/codebuild/output/src138728670/src/client/components/filters/CategoriesFilter.vue').default,SearchFilter: require('/codebuild/output/src138728670/src/client/components/filters/SearchFilter.vue').default,PriceFilter: require('/codebuild/output/src138728670/src/client/components/filters/PriceFilter.vue').default,InventoryFilter: require('/codebuild/output/src138728670/src/client/components/filters/InventoryFilter.vue').default,FacetsFilter: require('/codebuild/output/src138728670/src/client/components/filters/FacetsFilter.vue').default,SupplierFilter: require('/codebuild/output/src138728670/src/client/components/filters/SupplierFilter.vue').default})
