
  export default {
    props: ['suppliers', 'selectedSuppliers'],
    methods: {
      onSupplierFilter($e) {
        this.$emit('onSupplierFilter', $e);
      },
    },
    computed: {
      suppliersWithProductsCount() {
        return this.suppliers
          .filter((supplier) => supplier.productCount > 0)
          .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1));
      },
    },
  };
