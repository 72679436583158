import { reservedKeys } from '../utils/constants';

const SUPPLIER_URL_EXP = /^\/supplier\/([^\/?]+)/;

const traverse = function(callback, callbackList, key = 'childCategories', parent) {
  _.orderBy(this, ['name', 'activeProductsCount'], ['asc']);
  //console.log(this)
  for (let i = 0; i < this.length; i++) {
    if (!this[i]._parent && parent && typeof this[i]._parent === 'object') this[i]._parent = parent;
    if (Array.isArray(this[i][key])) {
      traverse.call(this[i][key], callback, callbackList, key, this[i]);
    }

    callback(this[i]);
  }
  if (callbackList) callbackList(this);
  return this;
};
function assignAllRelated(item, callback, key = '_parent') {
  if (item[key]) {
    callback(item[key], item);
    assignAllRelated.call({}, item[key], callback, key);
  }
}
Array.prototype.traverse = traverse;
const normalizeList = (list, key = 'name') => {
  const l = {};
  for (let i = 0; i < list.length; i++) {
    if (list[i].childCategories && list[i].childCategories.length) {
      list[i].childCategories = normalizeList(list[i].childCategories, key);
    }
    if (!l[list[i][key]]) {
      l[list[i][key]] = list[i];
    } else {
      if (list[i].childCategories)
        l[list[i][key]].childCategories = [...l[list[i][key]].childCategories, ...list[i].childCategories];
    }
  }
  return Object.keys(l).map((el) => l[el]);
};

const productsFilterMixinCategories = {
  data() {
    let searchCategoriesList = [];
    if (this.esSearch && 0) {
      searchCategoriesList = this.categories;
    } else {
      try {
        const categories = normalizeList(this.categories).map((cat) => {
          if (!cat.childCategories) cat.childCategories = [];
          let childCategories = cat.childCategories
            .filter((e) => e)
            .map((child) => {
              if (this.allCategories.length && typeof this.categoriesCount[child.name] === 'number')
                child.activeProductsCount = this.categoriesCount[child.name];
              return child;
            });
          //childCategories = _.sortBy(childCategories, 'name')
          if (this.allCategories.length) {
            (cat.rootCategory || cat).activeProductsCount =
              childCategories.map((child) => parseInt(child.activeProductsCount || 0)).reduce((a, b) => a + b, 0) ||
              this.categoriesCount[cat.name];
          }
          return Object.assign({}, { ...(cat.rootCategory || cat), childCategories });
        });

        const path = (this.$route.path || '').toLowerCase();
        try {
          let selected = [];
          traverse.call(categories, (cat) => {
            const isSelected = !!(cat.slug && path.match(cat.slug.toLowerCase()));
            if (isSelected) {
              selected.push(cat);
            }
            if (cat.childCategories && cat.childCategories.length) {
              cat.activeProductsCount = cat.childCategories
                .map((el) => el.activeProductsCount)
                .reduce((a, b) => a + b, 0);
              if (cat._activeProductsCount) cat.activeProductsCount += cat._activeProductsCount;
            }
            cat.childCategories = _.orderBy(cat.childCategories, ['name'], ['asc']);
          });
          if (selected.length) {
            if (selected.length > 1) {
              let moreMath = selected.map((cat) => path.match(cat.slug.toLowerCase())[0].length);
              const max = Math.max.call(this, ...moreMath);
              selected = selected.splice(
                moreMath.findIndex((e) => e === max),
                1
              );
            }

            selected[0].selected = true;
            selected[0].isExpanded = true;
            assignAllRelated(selected[0], (parent, child) => {
              parent.isExpanded = child.isExpanded = true;
            });
          }
        } catch (e) {
          console.log(e);
        }

        for (let i = 0; i < categories.length; i++) {
          const item = categories[i];
          if (item.parentCategory) {
            for (let j = 0; j < categories.length; j++) {
              if (categories[j]._id === item.parentCategory._id) {
                categories[j].childCategories.push(item);
                categories[j].activeProductsCount = categories[j].activeProductsCount + item.activeProductsCount;
                categories.splice(i--, 1);
                break;
              }
            }
          }
        }
        // searchCategoriesList = categories.some((cat) => isNaN(cat.activeProductsCount)) ? [] : categories;
        searchCategoriesList = categories?.filter((cat) => !isNaN(cat.activeProductsCount));
      } catch (e) {}
    }
    return {
      searchCategoriesList,
    };
  },
  computed: {
    searchCategories() {
      let categories = [...this.searchCategoriesList].sort((a, b) => a.name?.localeCompare(b.name));
      return this.loadedAll ? [...categories] : [...categories.splice(0, this.categoriesLimit)];
    },
    canShowAll() {
      return (
        !this.loadedAll &&
        this.searchCategories.length &&
        ((!this.$route.params.pathMatch && this.searchCategories.length) ||
          (this.$route.params.pathMatch && this.allCategories.length > this.searchCategories.length))
      );
    },
  },
  methods: {
    sortedCategories(categories) {
      return _.orderBy(categories, ['name', 'activeProductsCount'], ['asc']);
    },
    searchCategoryLink(category) {
      let search;
      let query = { ...this.$route.query };
      if (this.$route.path.toLowerCase().match('/products/no-minimum')) {
        query['no-minimum'] = 'yes';
      } else if (this.$route.path.toLowerCase().match('/products/closeout')) {
        query['closeout'] = 'yes';
      }
      if (Object.keys(query).length) {
        query = `?${Object.keys(query)
          .filter((e) => query[e] !== undefined && query[e] !== null && query[e] !== '')
          .map((e) => `${e}=${query[e]}`)
          .join('&')}`;
      } else {
        query = '';
      }
      if (this.$route.params.pathMatch && this.$route.params.pathMatch.includes('search/')) {
        search = this.$route.params.pathMatch.replace('search/', '');
      } else if (this.$route.path.includes('search/')) {
        search = this.$route.params.pathMatch;
      }
      if (SUPPLIER_URL_EXP.test(this.$route.fullPath)) {
        const [, supplier] = this.$route.fullPath.match(SUPPLIER_URL_EXP);
        if (search) {
          this.query.suppliersToFilter = supplier;
        }
        return (
          (search ? `/cat/${category.slug}/search/${search}` : `/cat/${category.slug}/supplier/${supplier}`) + query
        );
      } else if (this.$route.params.facet) {
        return `/cat/${category.slug}/${this.$route.params.slug}/${this.$route.params.facet}${query}`;
      } else if (this.$route.params.slug && !reservedKeys.includes(this.$route.params.slug)) {
        if (this.$route.path.includes('cat/') && this.$route.path.split('/').length > 3) {
          return `/cat/${category.slug}/${this.$route.path
            .split('/')
            .splice(3)
            .join('/')}${query}`;
        }
        return `/cat/${category.slug}/${this.$route.params.slug}${query}`;
      } else if (search) {
        return `/cat/${category.slug}/search/${search}${query}`;
      }
      return `/cat/${category.slug}${query}`;
    },
  },
};

export { productsFilterMixinCategories as default };
