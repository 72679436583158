
  import productsFilterMixinCategories from '@/plugins/productsFilterMixinCategories';
  export default {
    components: {
      CategoriesFilterItem: () => import('./CategoriesFilterItem'),
    },
    mixins: [productsFilterMixinCategories],
    props: {
      cat: Object,
      expanded: Boolean,
      total: Number,
      isExpand: Boolean,
      query: {
        type: Object,
        default: () => {},
      },
    },
  };
