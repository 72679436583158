
  import 'vue-slider-component/dist-css/vue-slider-component.css';
  import 'vue-slider-component/theme/default.css';

  export default {
    components: {
      VueSlider: () => import('vue-slider-component/dist-css/vue-slider-component.umd.min.js'),
    },
    props: {
      priceRange: Array,
      rangeData: Array,
    },
    data() {
      return {
        componentPriceRange: this.priceRange,
        dotOptions: [
          {
            disabled: false,
          },
          {
            style: ['background-color:#f8f8f8'],
          },
        ],
      };
    },
    methods: {
      priceRangeChanged: _.debounce(function(range) {
        this.$emit('priceRangeChanged', range);
      }, 1000),
    },
  };
